<template>
    <SocialChat icon :attendants="attendants">
        <template #header>
            <p>Para chatear por WhatsApp, elige a uno de nuestros asistentes.</p>
        </template>
        <template #button>
            <img src="/whatsapp.webp" width="50" alt="icon whatsapp" aria-hidden="true">
        </template>
        <template #footer>
            <h4 style="font-weight: 400;">Horario de atención: 8 am a 5 pm</h4>
        </template>
    </SocialChat>
</template>
<script>
import { SocialChat } from 'vue-social-chat'
import 'vue-social-chat/dist/style.css'
export default {
    components: {
        SocialChat,
    },
    data() {
        return {
        };
    },
    setup() {
        const attendants = [
            {
                app: 'whatsapp',
                label: 'Información general',
                name: 'Oficina Regional La Paz',
                number: '59176772796',
                avatar: {
                    src: '/avatar-1.webp',
                    alt: 'Alan Ktquez avatar'
                }
            },
        ]

        return { attendants }
    },
    methods: {

    },
};
</script>
<style>
.vsc-popup-header {
    background-color: #46c056;
    border-bottom-color: #289d37;
}

.vsc-popup-body {
    border-left: 1px solid #00000069;
    border-right: 1px solid #00000069;
}

.vsc-popup-footer {
    border-left: 1px solid #00000069;
    border-right: 1px solid #00000069;
    border-bottom: 1px solid #00000069;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.vsc-popup-button {
    background-color: #46c056;

}

:root {
    --vsc-outline-color: #46c056;
}
</style>
 