<template>
  <div style="margin-top: 100px;">
    <div
      style="display: flex; justify-content: center; align-items: center; min-height: 300px; background-image: url('/bg/bg1.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">

      <div style="background-color: #ffffff80; width: 100%;" class="pa-3 text-center">
        <v-img src="/logo0.png" height="150px"></v-img>
        <div class="text-h6 text-md-h5 text-lg-h5" style="color: #066e6c; font-weight: 800;">INGENIERÍA E INFRAESTRUCTURA PARA SISTEMA DE
          COMUNICACIÓN</div>
      </div>
    </div>
    <div
      style="background-image: url('/bg/bg2.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">
      <v-row align="center" class="justify-start pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 10 : 12">
          <v-card variant="flat" color="#00000060">
            <v-card-text>
              <div class="text-h4" style="color: #ee751f;"> En IIFCOM</div>
              <p style="color: white;">
                Nos especializamos en brindar servicios de alta calidad
                en el ámbito de las telecomunicaciones. Nuestra oferta de servicios
                abarca una amplia gama de soluciones para satisfacer las necesidades
                de nuestros clientes.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pa-4 mx-auto" style="position: relative; z-index: 2; max-width: 1200px; justify-content: center;">
        <v-col :cols="screenWidth >= 750 ? 4 : 12" class="text-center">
          <v-card variant="flat" color="#1d1d1d" style="border: #ffffff solid 1px; height: 100%;">
            <v-card-text style="background-color: #ee751f;">
              <div class="text-h6" style="color: #efff60; text-shadow: #2c2b30 3px 3px 4px;">Equipamiento Telecom</div>
              <div class="text-center my-3">
                <v-icon size="40" color="#004a53" class="text-center">mdi-lan-pending</v-icon>
              </div>
            </v-card-text>
            <v-card-text>
              <p class="pa-1" style="color: white; font-size: 0.9rem;">
                Suministramos equipos de última generación para telecomunicaciones, mejorando la conectividad.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 4 : 12" class="text-center">
          <v-card variant="flat" color="#1d1d1d" style="border: #ffffff solid 1px; height: 100%;">
            <v-card-text style="background-color: #ee751f;">
              <div class="text-h6" style="color: #efff60; text-shadow: #2c2b30 3px 3px 4px;">
                Redes y Seguridad
              </div>
              <div class="text-center my-3">
                <v-icon size="40" color="#004a53" class="text-center">mdi-cctv</v-icon>
              </div>
            </v-card-text>
            <v-card-text>
              <p class="pa-1" style="color: white; font-size: 0.9rem;">
                Especialistas en implementación y gestión de redes, CCTV y control de acceso, garantizando entornos
                seguros.
              </p>
            </v-card-text>

          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 4 : 12" class="text-center">
          <v-card variant="flat" color="#1d1d1d" style="border: #ffffff solid 1px; height: 100%;">
            <v-card-text style="background-color: #ee751f;">
              <div class="text-h6" style="color: #efff60; text-shadow: #2c2b30 3px 3px 4px;">
                Soluciones y Servicios</div>
              <div class="text-center my-3">
                <v-icon size="40" color="#004a53" class="text-center">mdi-abacus</v-icon>
              </div>
            </v-card-text>
            <v-card-text>
              <p class="pa-1" style="color: white; font-size: 0.9rem;">
                Desde diseño de redes hasta mantenimiento, ofrecemos soluciones integrales y servicios adicionales.
              </p>
            </v-card-text>

          </v-card>
        </v-col>

      </v-row>
      <div style="height: 50px;"></div>
    </div>




    <v-row align="center" justify="center" style="max-width: 1200px;" class="flex-wrap-reverse pa-4 mx-auto">
      <v-col :cols="screenWidth >= 750 ? 6 : 12" class="order-2">
        <v-card variant="flat" color="transparent">
          <v-card-text>
            <div class="text-h5 text-left" style="color: #ee751f;">
              <b>Venta de equipos y accesorios de Telecomunicaciones</b>
              <div style="border-radius: 5px; height: 3px; background-color: #ee751f;"></div>
            </div>
            <br>
            <ul class="pa-1" style="font-size: 0.9rem; line-height: 1.5;">
              <li>Provisión de equipamiento para telecomunicaciones.</li>
              <li>Provisión de materiales para redes de telecomunicaciones terrestres.</li>
              <li>Provisión de materiales para redes de telecomunicaciones subterráneas.</li>
              <li>Provisión de materiales para redes de fibra óptica.</li>
              <li>Provisión de materiales para sistemas de vigilancia.</li>
              <li>Provisión de materiales para sistemas eléctricos.</li>
              <li>Provisión de materiales para sistemas eléctricos y SPAT.</li>
              <li>Provisión de materiales para sistemas satelitales.</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="screenWidth >= 750 ? 6 : 12" class="order-1">
        <v-img style="max-height: 350px;" src="/img/img1.webp">
        </v-img>
      </v-col>
    </v-row>

    <div
      style="background-color: #ee751f; background-image: url('/bg/bg3.webp'); background-size: ; background-repeat: no-repeat; height: 100%; background-position: center;">
      <v-row align="center" class="justify-center pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card variant="flat" color="transparent">
            <v-card-title id="style-title"></v-card-title>
            <v-card-text>
              <div class="text-h5 text-left" style="color: #ffffff; ">
                <b>Productos y Servicios</b>
                <div style="border-radius: 5px; height: 3px; background-color: white; max-width: 290px;"></div>
              </div>
              <br>
              <ul class="pa-1" style="color: #efff60; font-size: 0.9rem; line-height: 1.5;">
                <li>Telecomunicaciones</li>
                <li>Redes Metropolitanas</li>
                <li>Enlaces punto a punto</li>
                <li>Redes LAN</li>
                <li>Cableado Estructurado</li>
                <li>Diseño de redes Wireless</li>
                <li>Sistemas de Seguridad</li>
                <li>Sistemas de CCTV</li>
                <li>Sistemas de Control de Acceso</li>
                <li>Telefonía Digital y sobre IP</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-img style="max-height: 400px;" src="/img/img2.webp">
          </v-img>
        </v-col>
      </v-row>
    </div>


    <v-row align="center" justify="center" style="max-width: 1200px;" class="flex-wrap-reverse pa-4 mx-auto">
      <v-col :cols="screenWidth >= 750 ? 6 : 12" class="order-2">
        <v-card variant="flat" color="transparent">
          <v-card-text>
            <div class="text-h5 text-left" style="color: #ee751f;">
              <b>Productos y servicios adicionales</b>
              <div style="border-radius: 5px; height: 3px; max-width: 420px; background-color: #ee751f;"></div>
            </div>
            <br>
            <ul class="pa-1" style="font-size: 0.9rem; line-height: 1.5;">
              <li>Diseño lógico de Redes </li>
              <li>Certificación de Redes </li>
              <li>Pólizas de Mantenimiento </li>
              <li>Diseño de redes VPN </li>
              <li>Instalación y Configuración de Servidores </li>
              <li>Instalación y Configuración de Antivirus Corporativo </li>
              <li>Mantenimiento y reparación de Computadoras de escritorio y portátiles.</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="screenWidth >= 750 ? 6 : 12" class="order-1">
        <v-img style="max-height: 350px;" src="/img/img3.webp">
        </v-img>
      </v-col>
    </v-row>


    <div style="background-color: #686a6c;">
      <div class="py-4 text-center mx-auto text-white" style="max-width: 1200px;">
        En IIFCOM, no solo ofrecemos productos y servicios;
        brindamos soluciones completas que impulsan el éxito
        y la eficiencia de tu infraestructura de comunicaciones.
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({

  data: () => ({
    screenWidth: window.innerWidth,
  }),

  methods: {
    updateWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateWidth);
  }
});
</script>
