<template>
  <v-app>

    <AppBar />
    <div id="app">
      <router-view />
    </div>
    <SocialComp></SocialComp>
    <FooterBar />

  </v-app>
</template>

<script>
import SocialComp from './components/SocialComp.vue';
import AppBar from './components/AppBar.vue'
import FooterBar from './components/FooterBar.vue';
export default {
  name: 'App',

  data: () => ({
    //
  }),

  components: {
    AppBar,
    FooterBar,
    SocialComp
  },
}
</script>

<style>
#app {
  font-family: 'Poppins', sans-serif;
}


p {
  line-height: 2;
  font-size: 1.1rem;
}

.text-h5 {
  font-family: 'Poppins', sans-serif !important;
}

.text-h4 {
  font-family: 'Poppins', sans-serif !important;
}

.text-h6 {
  font-family: 'Poppins', sans-serif !important;
}
</style>
