<template>
    <v-app-bar height="100" fixed>
        <v-toolbar color="#ee751f" height="100">
            <router-link :to="{ name: 'home' }">
                <v-img class="ms-8" :width="160" src="/logo0.png"></v-img>
            </router-link>

            <v-spacer></v-spacer>


            <div v-if="screenWidth > 730">

                <router-link class="mr-4" to="home" style="text-decoration: none; color: white;">
                    INICIO
                </router-link>

                <router-link class="mr-4" to="servicios" style="text-decoration: none; color: white;">
                    NUESTROS SERVICIOS
                </router-link>

                <router-link class="mr-4" to="conocenos" style="text-decoration: none; color: white;">
                    CONOCENOS
                </router-link>

                <router-link class="mr-4" to="contactanos" style="text-decoration: none; color: white;">
                    CONTÁCTANOS
                </router-link>
            </div>


            <div v-else>
                <v-menu transition="scale-transition">
                    <template v-slot:activator="{ props }">
                        <v-app-bar-nav-icon class="ms-2" v-bind="props"></v-app-bar-nav-icon>
                    </template>
                    <v-list style="width: 210px;">

                        <v-btn block variant="text" to="home">
                            <v-icon style="margin-left: -120px;" color="#ee751f">mdi-home</v-icon>
                            <span>
                                &nbsp;
                                Inicio
                            </span>
                        </v-btn>


                        <v-divider></v-divider>
                        <v-btn block variant="text" to="servicios">
                            <v-icon style="margin-left: 0px;" color="#ee751f">mdi-account-hard-hat</v-icon>
                            <span>
                                &nbsp;
                                Nuestros servicios
                            </span>
                        </v-btn>
                        <v-divider></v-divider>
                        <v-btn block variant="text" to="conocenos">
                            <v-icon style="margin-left: -70px;" color="#ee751f">mdi-information</v-icon>
                            <span>
                                &nbsp;
                                Conocenos
                            </span>
                        </v-btn>
                        <v-divider></v-divider>
                        <v-btn block variant="text" to="contactanos">
                            <v-icon style="margin-left: -50px;" color="#ee751f">mdi-card-account-mail</v-icon>
                            <span>
                                &nbsp;
                                Contáctanos
                            </span>
                        </v-btn>

                    </v-list>
                </v-menu>
            </div>



        </v-toolbar>
    </v-app-bar>
</template>
  
  
  

<script>
import { defineComponent } from 'vue';

export default defineComponent({

    data: () => ({
        screenWidth: window.innerWidth,
    }),

    methods: {
        updateWidth() {
            this.screenWidth = window.innerWidth;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateWidth);
    }
});
</script>