<template>
  <div style="margin-top: 100px;">
    <div
      style="min-height: 300px; background-image: url('/img/img20.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">
      <v-row class="pa-4" style="min-height: 300px; justify-content: center; align-items: center;">
        <v-col cols="12">
          <div class="text-h3 text-md-h2 text-lg-h1 text-left" style="font-weight: 800; color: #152a47;">
            Contáctenos
          </div>
        </v-col>
        <v-col cols="12">
          <div class="text-h6 text-md-h5 text-lg-h4 text-left pa-2"
            style="border-radius: 10px; background-color: #ffffff80; font-weight: 600; color: #152a47;">
            Hablemos más sobre nuestros servicios y cómo podemos ayudar a que su negocio crezca.
          </div>
        </v-col>
      </v-row>
    </div>
    <div >
      <v-row align="center" justify="center" class=" pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card variant="flat" color="transparent">
            <v-card-text>

              <div class="text-h5 text-left mb-5" style="color: #152a47;">
                <b>Dejanos un mensaje</b>
              </div>

              <v-form ref="form" @submit.prevent="sendEmail()" style="width: 100%;">
                <v-text-field :readonly="loading" v-model="nombre" :maxlength="100" class="mb-3" :rules="[required]"
                  prepend-inner-icon="mdi-account" counter="100" label="Nombre" variant="outlined"></v-text-field>
                <v-text-field :readonly="loading" v-model="contacto" :maxlength="100" class="mb-3" :rules="[required]"
                  prepend-inner-icon="mdi-email" counter="100" label="Correo, telefono o celular"
                  variant="outlined"></v-text-field>
                <v-text-field :readonly="loading" on v-model="asunto" :maxlength="100" class="mb-3" :rules="[required]"
                  prepend-inner-icon="mdi-tag" counter="100" label="Asunto" variant="outlined"></v-text-field>
                <v-textarea :readonly="loading" v-model="mensaje" :maxlength="500" :rules="[required2]"
                  prepend-inner-icon="mdi-message" auto-grow counter="500" label="Mensaje"
                  variant="outlined"></v-textarea>
                <div class="text-center mt-5">
                  <v-btn :loading="loading" type="submit" color="#254b5e">
                    Enviar
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 8">
          <div style="background-color: #fff; border-radius: 10px;" class="pa-4">
            <v-img style="border-radius: 10px;  max-height: 350px;" src="/logo0.png">
            </v-img>
          </div>

        </v-col>
      </v-row>
    </div>
  </div>

  <v-dialog v-model="dialog" :scrim="true" width="300">
    <v-card color="success">
      <v-card-text class="text-center">
        <v-icon size="40">mdi-check-circle</v-icon>
        <br>
        <span style="color: #fff;">
          Tu mensaje ha sido enviado, pronto nos comunicaremos contigo.
        </span>

      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialog2" :scrim="false" width="300">
    <v-card color="yellow">

      <v-card-text class="text-center">
        <v-icon size="40">mdi-alert-circle</v-icon>
        <br>
        No se pudo enviar tu mensaje, por favor inténtalo más tarde.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>

import emailjs from '@emailjs/browser';
export default {
  components: {


  },
  data() {
    return {
      screenWidth: window.innerWidth,
      nombre: '',
      contacto: '',
      asunto: '',
      mensaje: '',
      loading: false,
      dialog: false,
      dialog2: false
    };
  },
  watch: {
    dialog(val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 5000)
    },
    dialog2(val) {
      if (!val) return
      setTimeout(() => (this.dialog2 = false), 5000)
    },
  },
  methods: {

    async sendEmail() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.loading = true
      const parametros = {
        nombre: this.nombre,
        contacto: this.contacto,
        asunto: this.asunto,
        mensaje: this.mensaje
      };

      emailjs.send('service_b6lkffd', 'template_2ih2oej', parametros, 'FKrpt0nucnxrLqw6E')
        .then((result) => {
          this.nombre = ''
          this.contacto = ''
          this.asunto = ''
          this.mensaje = ''
          this.$nextTick(() => {
            this.$refs.form.reset();
          });
          this.dialog = true
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
          this.dialog2 = true
        })
        .finally(() => {
          this.loading = false
        });


    },


    updateWidth() {
      this.screenWidth = window.innerWidth;
    },

    required(v) {
      const maxLength = 100;
      if (!v) return 'Este campo es obligatorio.';
      if (v.length > maxLength) return `El texto es muy largo. No debe exceder los ${maxLength} caracteres.`;
      return true;
    },
    required2(v) {
      const maxLength = 500;
      if (!v) return 'Este campo es obligatorio.';
      if (v.length > maxLength) return `El texto es muy largo. No debe exceder los ${maxLength} caracteres.`;
      return true;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateWidth);
  }

};
</script>