<template>
  <div style="margin-top: 100px;">


    <div
      style="background-image: url('/bg/bg5.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center; min-height: 250px;"
      class="d-flex align-center justify-center">
      <v-row class="pa-4" style="max-width: 1200px;">
        <v-col cols="12">
          <div class="text-h4 text-md-h3 text-lg-h2 text-center" style="font-weight: 800; color: #fff;">
            IIFCOM
          </div>
          <div class="text-h5 text-center pa-2 mx-auto"
            style="font-weight: 800; color: #066e6a; background-color: #ffffffcb; border-radius: 10px;">
            INGENIERÍA E INFRAESTRUCTURA PARA SISTEMA DE COMUNICACIÓN
          </div>
        </v-col>
      </v-row>
    </div>




    <div>
      <v-row align="start" class="justify-center pa-2 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card color="transparent" variant="flat">
            <v-card-text>
              <div class="text-h4" style="color: #066e6a; font-weight: 600;">
                Misión
              </div>
              <p style="background-color: #066e6a; line-height: 1.5; color: white; font-size: 1rem; border-radius: 10px;"
                class="pa-2">
                Crear soluciones integrales en telecomunicaciones, proveyendo a nuestros clientes servicios tecnológicos
                innovadores de alta calidad a través del trabajo especializado de nuestro personal y de la gestión y
                adquisición
                de tecnología vanguardista.
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card color="transparent" variant="flat">
            <v-card-text>
              <div class="text-h4" style="color: #066e6a; font-weight: 600;">
                Visión
              </div>
              <p style="background-color: #066e6a; line-height: 1.5; color: white; font-size: 1rem; border-radius: 10px;"
                class="pa-2">
                Consolidarnos como la organización primera en la elección de soluciones de telecomunicación en el ámbito
                regional, expandiendo nuestra penetración en el mercado nacional.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>


    <div
      style="background-color: #ee751f; background-image: url('/bg/bg3.webp'); background-size: ; background-repeat: no-repeat; height: 100%; background-position: center;">
      <div class="text-h5 text-center py-5" style="color: #ffffff; ">
        <b>¿Por qué elegir a IIFCOM?</b>
      </div>
      <v-row class="pa-4 mx-auto" style="max-width: 1200px; justify-content: center;">
        <v-col :cols="screenWidth >= 500 ? 4 : 12">
          <v-card color="#1d1d1d" style="height: 100%; border: solid 2px white;">
            <v-img height="200px" src="/img/img16.webp">
            </v-img>
            <div class="d-flex justify-center">
              <h3>Calidad</h3>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 500 ? 4 : 12">
          <v-card color="#1d1d1d" style="height: 100%; border: solid 2px white;">
            <v-img height="200px" src="/img/img17.webp">
            </v-img>
            <div class="d-flex justify-center">
              <h3>Eficiencia</h3>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 500 ? 4 : 12">
          <v-card color="#1d1d1d" style="height: 100%; border: solid 2px white;">
            <v-img height="200px" src="/img/img18.webp">
            </v-img>
            <div class="d-flex justify-center">
              <h3>Puntualidad</h3>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>


    <div
      style="background-color: white; background-image: url('/bg/bg6.webp'); background-size: auto; background-repeat: no-repeat; height: 100%; background-position: center;">

      <v-row class="justify-center pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card variant="flat" color="transparent">
            <v-card-text>
              <div class="text-h5 text-left" style="color: #ee751f; ">
                <b>Nuestro Protocolo de Desarrollo</b>
                <div style="border-radius: 5px; height: 3px; background-color: #ee751f; max-width: 390px;"></div>
              </div>
              <br>
              <ul class="pa-1" style="background-color: #ffffff50; color: #000000; font-size: 0.9rem; line-height: 1.5;">
                <li>Diseño del proyecto a realizar</li>
                <li>Instalación y programación</li>
                <li>Verificación del funcionamiento</li>
                <li>Capacidad de personal</li>
                <li>Mantenimiento y servicio</li>
                <li>Consultoría</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-img style="max-height: 350px;" src="/img/img19.webp">
          </v-img>
        </v-col>
      </v-row>

    </div>


  </div>
  <br>
</template>


<script>
import { defineComponent } from 'vue';
export default defineComponent({

  data: () => ({
    screenWidth: window.innerWidth,
  }),

  methods: {
    updateWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateWidth);
  }
});
</script>