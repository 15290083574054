<template>
  <v-footer class="d-flex flex-column"
    style="background-image: url('/bg/bg9.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">
    <div>
      <v-icon class="ma-2" size="30" color="#ee751f">mdi-security-network</v-icon>
      <v-icon class="ma-2" size="30" color="#ee751f">mdi-lan</v-icon>
      <v-icon class="ma-2" size="30" color="#ee751f">mdi-nas</v-icon>
      <v-icon class="ma-2" size="30" color="#ee751f">mdi-wan</v-icon>
      <v-icon class="ma-2" size="30" color="#ee751f">mdi-toolbox</v-icon>
      <v-icon class="ma-2" size="30" color="#ee751f">mdi-antenna</v-icon>
    </div>

    <div style="width: 100%; max-width: 1200px;">
      <v-row>
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card variant="flat" color="transparent">
            <v-card-text>
              <div class="text-h5 text-white text-center">
                Servicios
              </div>
              <div>
                <p class="text-white" style="font-size: 1rem;">
                  <span style="color: #ee751f;">✔</span> Fibra Óptica
                </p>
                <p class="text-white" style="font-size: 1rem;">
                  <span style="color: #ee751f;">✔</span> Venta de equipos Telecom
                </p>
                <p class="text-white" style="font-size: 1rem;">
                  <span style="color: #ee751f;">✔</span> Trabajos en Obras civiles
                </p>
                <p class="text-white" style="font-size: 1rem;">
                  <span style="color: #ee751f;">✔</span> Cableado estructurado
                </p>
                <p class="text-white" style="font-size: 1rem;">
                  <span style="color: #ee751f;">✔</span> Cámaras de Vigilancia
                </p>
                <p class="text-white" style="font-size: 1rem;">
                  <span style="color: #ee751f;">✔</span> Sistemas Satelitales
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 12" class="text-start">
          <v-card variant="flat" color="transparent">
            <v-card-text>
              <div class="text-h5 text-white text-center">
                Informacion de contacto
              </div>
              <br>
              <div class="text-white" style="font-size: 1rem;">
                <v-icon color="#ee751f">mdi-map-marker</v-icon>
                <span>Avenida Periférica N° 444 - La Paz Bolivia</span>
              </div>
              <br>
              <div class="text-white" style="font-size: 1rem;">
                <v-icon color="#ee751f">mdi-email</v-icon>
                <span> info@iifcom.com</span>
              </div>
              <br>
              <div class="text-white" style="font-size: 1rem;">
                <v-icon color="#ee751f">mdi-phone-classic</v-icon>
                <span> 591 - 2216143</span>
              </div>
              <br>
              <div class="text-white" style="font-size: 1rem;">
                <v-icon color="#ee751f">mdi-phone</v-icon>
                <span> 591 - 76772796</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="text-white text-center">
      © {{ new Date().getFullYear() }} <strong>IIFCOM</strong>
      <br>
      Desarrollado por <a style="color: inherit;" href="https://www.codigoveloz.com" target="_blank">Código Veloz</a>.
      Todos los
      derechos reservados.
    </div>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    screenWidth: window.innerWidth,
  }),

  methods: {
    updateWidth() {
      this.screenWidth = window.innerWidth;
      this.sizeCategory = this.getSizeCategory(this.screenWidth);
    },
    getSizeCategory(width) {
      if (width < 600) return 'xs';
      if (width < 960) return 'sm';
      if (width < 1264) return 'md';
      if (width < 1904) return 'lg';
      return 'xl';
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateWidth);
  }
}
</script>
