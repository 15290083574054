<template>
  <div style="margin-top: 100px;">
    <div
      style="background-image: url('/bg/bg4.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">
      <v-row class="pa-4" style="justify-content: center; align-items: center;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <div class="text-h5 text-center" style="font-weight: 800; color: #ee751f;">
            Servicios que ofrecemos a nuestros clientes
          </div>
        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 8">
          <v-card color="transparent" variant="flat">
            <v-img src="/img/img4.webp">
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row align="center" justify="center" class=" pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">
          <v-card variant="flat" color="transparent">
            <v-card-text>

              <div class="text-h5 text-left" style="color: #ee751f;">
                <b>Obras civiles</b>
                <div style="max-width: 170px; border-radius: 5px; height: 3px; background-color: #ee751f;"></div>
              </div>
              <br>
              <ul class="pa-1" style="font-size: 0.9rem; line-height: 1.5;">
                <li>Diseño y ejecución de sistemas eléctricos: generadores, paneles solares, rectificadores, rack de
                  baterías.</li>
                <li>Canalizaciones urbanas para redes de cobre y fibra óptica.</li>
                <li>Canalizaciones rurales de larga distancia para redes de cobre y fibra óptica.</li>
                <li>Sistemas de pararrayos.</li>
                <li>Sistemas de puesta a tierra.</li>
                <li>Instalación de cámaras de inspección de todo tipo.</li>
                <li>Instalación de bases de armarios para todo tipo.</li>
                <li>Diseño y construcción de Data Centers de TIER 1, 2, 3.</li>
              </ul>

            </v-card-text>

          </v-card>
        </v-col>
        <v-col class="text-center" :cols="screenWidth >= 750 ? 6 : 8">
          <v-img style="border-radius: 10px; max-height: 350px;" src="/img/img5.webp">
          </v-img>
        </v-col>
      </v-row>
    </div>

    <div
      style="background-color: #ee751f; background-image: url('/bg/bg7.webp'); background-size: ; background-repeat: no-repeat; height: 100%; background-position: center;">
      <v-row align="center" class="flex-wrap-reverse justify-center pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12" class="order-2">

          <v-card variant="flat" color="transparent">
            <v-card-title id="style-title"></v-card-title>
            <v-card-text>
              <div class="text-h5 text-left" style="color: #ffffff; ">
                <b>Cableado estructurado</b>
                <div style="border-radius: 5px; height: 3px; background-color: white; max-width: 290px;"></div>
              </div>
              <br>
              <ul class="pa-1" style="color: #efff60; font-size: 0.9rem; line-height: 1.5;">
                <li>Instalación de Cableado estructurado Categoria 5E LAN, WAN, MAN.</li>
                <li>Instalación de Cableado estructurado Categoria 6, 6ª, 7, 7ª Ultra.</li>
                <li>Provisión de Materiales de cableado estructurado.</li>
                <li>Venta e instalación de Switch.</li>
                <li>Venta e Instalación de Router`s.</li>
                <li>Venta e instalación de Firewall.</li>
                <li>Venta e instalación de Rack y gabinetes.</li>
                <li>Venta de Accesorios de cableado estructurado.</li>
              </ul>
            </v-card-text>
          </v-card>


        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 8" class="order-1">
          <v-img style="border-radius: 10px; max-height: 350px;" src="/img/img6.webp">

          </v-img>
        </v-col>
      </v-row>
    </div>

    <div
      style="background-color: white; background-image: url('/bg/bg6.webp'); background-size: auto; background-repeat: no-repeat; height: 100%; background-position: center;">
      <v-row align="center" class="justify-center pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12">

          <v-card variant="flat" color="transparent">
            <v-card-title id="style-title"></v-card-title>
            <v-card-text>
              <div class="text-h5 text-left" style="color: #ee751f; ">
                <b>Cámaras de Vigilancia</b>
                <div style="border-radius: 5px; height: 3px; background-color: #ee751f; max-width: 290px;"></div>
              </div>
              <br>
              <ul class="pa-1" style="background-color: #ffffff50; color: #000000; font-size: 0.9rem; line-height: 1.5;">
                <li>Diseño de Redes para cámaras de vigilancia para colegios, industrias, oficinas y domicilios </li>
                <li>Instalación de cámaras externa y externa en tecnologías POE IP y NVR </li>
                <li>Instalación de Postes para cámaras exteriores </li>
                <li>Instalación de cámaras de seguridad ciudadana </li>
                <li>Instalación de sistemas de megafonía IP </li>
                <li>Instalación de cámaras ojo de pez </li>
                <li>Instalación de cámaras PTZ </li>
                <li>Instalación de Nodos o gabinetes exteriores </li>
                <li>Venta de cámaras de vigilancia IP POE </li>
                <li>Venta NVR de 8 accesos </li>
                <li>Venta de discos duros para NVR </li>
                <li>Venta de gabinetes para interiores </li>
                <li>Venta de inyectores POE </li>
                <li>Venta de accesorios de conexión para sistemas de video vigilancia </li>
              </ul>
            </v-card-text>
          </v-card>

        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 8">
          <v-img style="border-radius: 10px;  max-height: 350px;" src="/img/img7.webp">

          </v-img>
        </v-col>
      </v-row>
    </div>


    <div
      style="background-color: #ee751f; background-image: url('/bg/bg7.webp'); background-size: ; background-repeat: no-repeat; height: 100%; background-position: center;">
      <v-row align="center" class="flex-wrap-reverse justify-center pa-4 mx-auto" style="max-width: 1200px;">
        <v-col :cols="screenWidth >= 750 ? 6 : 12" class="order-2">

          <v-card variant="flat" color="transparent">
            <v-card-title id="style-title"></v-card-title>
            <v-card-text>
              <div class="text-h5 text-left" style="color: white; ">
                <b>Sistemas Satelitales</b>
                <div style="border-radius: 5px; height: 3px; background-color: white; max-width: 290px;"></div>
              </div>
              <br>
              <ul class="pa-1" style="color: #efff60; font-size: 0.9rem; line-height: 1.5;">
                <li>Instalación de antenas satelitales de 1.2 banda KU. </li>
                <li>Instalación de antenas satelitales de 1.8 banda KU. </li>
                <li>Instalación de antenas satelitales de 2.1 banda KU. </li>
                <li>Instalación de Internet satelital de 2 a 30 Mbps. </li>
                <li>Instalación de servicio transmisión de datos. satelital. </li>
                <li>Venta de accesorios satelitales. </li>
                <li>Asesoría en conexiones Satelitales. </li>
              </ul>
            </v-card-text>
          </v-card>

        </v-col>
        <v-col :cols="screenWidth >= 750 ? 6 : 8" class="order-1">
          <v-img style="border-radius: 10px; max-height: 350px;" src="/img/img8.webp">

          </v-img>
        </v-col>
      </v-row>
    </div>



    <div
      style="background-image: url('/bg/bg6.webp'); background-size: cover; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">
      <div class="text-h5 text-center  pa-10" style="font-weight: 800; color: #ee751f;">
        Redes Inalámbricas
      </div>
      <v-row justify="center" dense>
        <v-col :cols="screenWidth >= 500 ? 6 : 12" class="text-center pa-6">
          <v-card class="mx-auto" max-width="500" style="border: #ee751f solid 4px;">
            <v-img src="/img/img9.webp" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="250px" cover>
              <div class="text-white text-h6">Instalación de antenas sectoriales</div>

            </v-img>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 500 ? 6 : 12" class="text-center pa-6">
          <v-card class="mx-auto" max-width="500" style="border: #ee751f solid 4px;">
            <v-img src="/img/img11.webp" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="250px" cover>
              <div class="text-white text-h6">Mantenimiento de Torres</div>
            </v-img>
          </v-card>
        </v-col>

        <v-col :cols="screenWidth >= 500 ? 6 : 12" class="text-center pa-6">
          <v-card class="mx-auto" max-width="500" style="border: #ee751f solid 4px;">
            <v-img src="/img/img10.webp" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="250px" cover>
              <div class="text-white text-h6">Instalación de sistemas de Pararrayos</div>

            </v-img>
          </v-card>
        </v-col>
        <v-col :cols="screenWidth >= 500 ? 6 : 12" class="text-center pa-6">
          <v-card class="mx-auto" max-width="500" style="border: #ee751f solid 4px;">
            <v-img src="/img/img12.webp" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="250px" cover>
              <div class="text-white text-h6">Mantenimiento de Antenas</div>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="pb-8"
      style="background-image: url('/bg/bg8.webp'); background-size: ; background-repeat: no-repeat; width: 100%; height: 100%; background-position: center;">
      <div style="max-width: 1200px;" class="mx-auto pt-5">

        <div class="text-h5 text-center mb-3" style="font-weight: 800; color: #ee751f;">
          Fibra Óptica
        </div>

        <v-row justify="center" class="pa-2">
          <v-col :cols="screenWidth >= 750 ? 4 : 12">
            <v-card color="#1d1d1d" variant="flat" style="height: 100%; border: solid 3px #ee751f;">
              <v-img :height="200" src="/img/img13.webp" style="background-color: white;"></v-img>
              <div class="pa-2">
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Fusiones por arco eléctrico</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Certificaciones con OTDR</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Habilitación de Muflas externas</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Habilitación de Muflas Subterráneas</p>
              </div>
            </v-card>
          </v-col>
          <v-col :cols="screenWidth >= 750 ? 4 : 12">
            <v-card color="#1d1d1d" variant="flat" style="height: 100%; border: solid 3px #ee751f;">
              <v-img :height="200" cover src="/img/img14.webp"></v-img>
              <div class="pa-2">
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Habilitación de ODF</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Habitación de Patch Panel`s</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Habitación de Pig Tail`s</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta de Media Converter`s</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta cables Fibra Óptica</p>
              </div>
            </v-card>
          </v-col>

          <v-col :cols="screenWidth >= 750 ? 4 : 12">
            <v-card color="#1d1d1d" variant="flat" style="height: 100%; border: solid 3px #ee751f;">
              <v-img :height="200" src="/img/img15.webp" style="background-color: white;"></v-img>
              <div class="pa-2">
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta de Patch Cord`s </p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta de Accesorios de Fibra Óptica</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta de Fusionadoras</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta de OTDR</p>
                <p style="font-size: 1rem;"><span style="color: #ee751f;">✦</span> Venta de insumos en fibra Óptica</p>
              </div>
            </v-card>

          </v-col>
        </v-row>

      </div>
    </div>

  </div>
</template>


<script>
import { defineComponent } from 'vue';
export default defineComponent({

  data: () => ({
    screenWidth: window.innerWidth,
  }),

  methods: {
    updateWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateWidth);
  }
});
</script>